import React, { useMemo, useCallback } from 'react'
import { snakeCase } from 'lodash'

import {
  NavigationBarProps,
  NavigationRoute,
  ProfileAvatar,
  ProfileMenu
} from '@barracuda-internal/bds-core/dist/UnifiedComponents/UnifiedNavigation'
import { Typography } from '@barracuda-internal/bds-core'
import { FaviconEmail } from '@barracuda-internal/bds-core/dist/Logos/Favicons'
import { BarChart } from '@barracuda-internal/bds-core/dist/Icons/Charts'
import { Email } from '@barracuda-internal/bds-core/dist/Icons/Email'
import {
  User,
  Language,
  Apps,
  ExitToApp,
  OpenInNew,
  RecentActors,
  Settings,
  SupervisorAccount
} from '@barracuda-internal/bds-core/dist/Icons/Core'
import ProductSwitcher from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/ProductSwitcher'

import { ProductDataSubscriptionGroup } from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/types'
import { Account } from 'global/types/api/accountType'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import browserHistory from 'global/lib/routes/browserHistory'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'
import { useFormatMessage } from 'global/lib/localization'
import * as datetime from 'global/lib/datetime'
import { createUrlGenerator } from 'global/lib/routes/routesConfig'
import { logout } from 'global/redux/features/auth/authSlice'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

const BASE_I18N_KEY = 'sen.app.navbar'

export default function useNavbarLogic(): NavigationBarProps[] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [accessTokenLib] = useAccessTokenLib()
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const dispatch = useAppDispatch()
  const { accessTokenId, activePath, splitStore, userBccId, userEmail, userName } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    activePath: _stores.app.activePath.url || '',
    splitStore: _stores.splitio,
    userBccId: _stores.user.data?.bccUserId,
    userEmail: _stores.user.data?.email,
    userName: _stores.user.data?.displayName || ''
  }))

  const isRouteDisabled = useMemo(
    () => activePath === routesConfig.START_TRIAL.path || activePath === routesConfig.CLEANUP_TABLE.path,
    [activePath]
  )

  const accounts = userDataLib.getAccounts()
  const isAdmin = useMemo(() => userDataLib.isUserAdmin(accessTokenId), [accessTokenId, userDataLib])

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])

  const isLicenseComplianceOn = useMemo(() => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_COMPLIANCE, accountId), [
    accountId,
    splitStore
  ])

  const etsTokens = useMemo(
    () =>
      accounts.flatMap((account: Account) =>
        account.accessTokens
          .filter(accessToken => accessToken.products.includes('ets'))
          .map(accessToken => accessToken.id)
      ),
    [accounts]
  )

  const ets = useMemo(
    () => ({
      appName: formatMessage('productSwitcher.ets'),
      url: `${config.domains.ets}/report/${etsTokens[0]}`
    }),
    [etsTokens, formatMessage]
  )

  const incidentResponse = useMemo(
    () => ({
      appName: formatMessage('productSwitcher.incidentResponse'),
      url: `${config.domains.forensics}/report/${accessTokenId}`
    }),
    [accessTokenId, formatMessage]
  )

  const isLicenseVisible = useMemo(() => {
    return (
      !userDataLib.isMspManagedAccount(accessTokenId) &&
      isLicenseComplianceOn &&
      accessTokenLib.hasSentinelEntitlement(accessTokenId)
    )
  }, [accessTokenId, accessTokenLib, isLicenseComplianceOn, userDataLib])

  const onNavigate = useCallback((path: string) => {
    browserHistory.push(path)
  }, [])

  const generatePath = useCallback(
    (path: string) => {
      const createUrl = createUrlGenerator(path)
      return `${createUrl({ reportId: accessTokenId })}`
    },
    [accessTokenId]
  )

  // TODO remove BUNDLE1_SIDE_MENU_ITEMS once DFP standalone goes live
  const BUNDLE1_SIDE_MENU_ITEMS: NavigationRoute[] = [
    {
      path: generatePath(routesConfig.DASHBOARD.path),
      icon: <Email />,
      name: formatMessage(`${snakeCase(routesConfig.DASHBOARD.id)}`),
      disabled: isRouteDisabled
    },
    {
      path: generatePath(routesConfig.ACCOUNT_TAKEOVER.path),
      icon: <User />,
      name: formatMessage(`${snakeCase(routesConfig.ACCOUNT_TAKEOVER.id)}`),
      disabled: isRouteDisabled
    },

    {
      path: generatePath(routesConfig.UNIFIED_REPORTING_ROOT.path),
      icon: <BarChart />,
      name: formatMessage(`${snakeCase(routesConfig.UNIFIED_REPORTING_ROOT.id)}`),
      disabled: isRouteDisabled,
      children: [
        {
          name: formatMessage(`${snakeCase(routesConfig.REPORT_LIST.id)}`),
          disabled: isRouteDisabled,
          path: generatePath(routesConfig.REPORT_LIST.path)
        },
        {
          name: formatMessage(`${snakeCase(routesConfig.SCHEDULED_REPORTS.id)}`),
          disabled: isRouteDisabled,
          path: generatePath(routesConfig.SCHEDULED_REPORTS.path)
        }
      ]
    },
    {
      path: generatePath(routesConfig.SETTINGS.path),
      icon: <Settings />,
      name: formatMessage(`${snakeCase(routesConfig.SETTINGS.id)}`),
      disabled: isRouteDisabled
    }
  ]

  const ALL_SIDE_MENU_ITEMS: NavigationRoute[] = [
    {
      path: generatePath(routesConfig.DASHBOARD.path),
      icon: <Email />,
      name: formatMessage(`${snakeCase(routesConfig.DASHBOARD.id)}`),
      disabled: isRouteDisabled
    },
    {
      path: generatePath(routesConfig.ACCOUNT_TAKEOVER.path),
      icon: <User />,
      name: formatMessage(`${snakeCase(routesConfig.ACCOUNT_TAKEOVER.id)}`),
      disabled: isRouteDisabled
    },
    // TODO: remove DOMAIN_FRAUD once DFP standalone goes live
    {
      path: generatePath(routesConfig.DOMAIN_FRAUD.path),
      icon: <Language />,
      name: formatMessage(`${snakeCase(routesConfig.DOMAIN_FRAUD.id)}`),
      disabled: isRouteDisabled
    },
    {
      path: generatePath(routesConfig.UNIFIED_REPORTING_ROOT.path),
      icon: <BarChart />,
      name: formatMessage(`${snakeCase(routesConfig.UNIFIED_REPORTING_ROOT.id)}`),
      disabled: isRouteDisabled,
      children: [
        {
          name: formatMessage(`${snakeCase(routesConfig.REPORT_LIST.id)}`),
          disabled: isRouteDisabled,
          path: generatePath(routesConfig.REPORT_LIST.path)
        },
        {
          name: formatMessage(`${snakeCase(routesConfig.SCHEDULED_REPORTS.id)}`),
          disabled: isRouteDisabled,
          path: generatePath(routesConfig.SCHEDULED_REPORTS.path)
        }
      ]
    },
    {
      path: generatePath(routesConfig.SETTINGS.path),
      icon: <Settings />,
      name: formatMessage(`${snakeCase(routesConfig.SETTINGS.id)}`),
      disabled: isRouteDisabled
    }
  ]

  const DP_SIDE_MENU_ITEMS: NavigationRoute[] = [
    {
      path: generatePath(routesConfig.DOMAIN_FRAUD.path),
      icon: <Language />,
      name: formatMessage(`${snakeCase(routesConfig.DOMAIN_FRAUD.id)}`),
      disabled: isRouteDisabled
    }
  ]

  /* With the new PAM V2 activation we need to support bundle backwards compatibility until the DFP standalone is deployed */
  const routes: NavigationRoute[] = useMemo(() => {
    const hasSentinel = productLib.hasSentinelProduct(accessTokenId)
    const hasDFP = productLib.hasDFPProduct(accessTokenId)

    switch (true) {
      case hasSentinel && !hasDFP:
        return BUNDLE1_SIDE_MENU_ITEMS
      case hasSentinel && hasDFP:
        return ALL_SIDE_MENU_ITEMS
      case !hasSentinel && hasDFP:
        return DP_SIDE_MENU_ITEMS
      default:
        return ALL_SIDE_MENU_ITEMS
    }
  }, [ALL_SIDE_MENU_ITEMS, BUNDLE1_SIDE_MENU_ITEMS, DP_SIDE_MENU_ITEMS, accessTokenId, productLib])

  // TODO: This function will be moved to global lib for use across IP, IR, DFP and ETS
  const getInitials = useCallback(name => {
    const names = name.split(' ')
    const initials = names.map((n: string) => n[0]).join('')
    return initials.toUpperCase()
  }, [])

  const onGoToLicense = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVIEW_LICENSES)
    routesConfig.LICENSE.goto({
      reportId: accessTokenId
    })
  }, [accessTokenId])

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  const serialNumber = useMemo(() => productLib.getSentinelSerialNumberForAccessToken(accessTokenId), [
    accessTokenId,
    productLib
  ])

  const expiration = useMemo(() => productLib.getSentinelSerialExpiryForAccessToken(accessTokenId), [
    accessTokenId,
    productLib
  ])

  return useMemo(
    () => [
      {
        title: '',
        currentPath: activePath,
        onNavigate,
        options: [
          // TODO: will update this ProductSwitcher logic once we start working on BNFIR-3324, Apps will not depend on ets token for IP unified nav phase 2, it will always be in the UI
          ...(etsTokens.length > 0
            ? [
                {
                  name: formatMessage('options.apps'),
                  icon: <Apps />,
                  // TODO: will replace [] with subscription data in BNFIR-3324
                  menu: (
                    <ProductSwitcher
                      subscriptionGroups={[] as ProductDataSubscriptionGroup[]}
                      ets={ets}
                      incidentResponse={incidentResponse}
                    />
                  )
                }
              ]
            : []),

          {
            name: formatMessage('options.profile'),
            icon: <ProfileAvatar size={28}>{getInitials(userName)}</ProfileAvatar>,
            menu: (
              <ProfileMenu
                icon={<ProfileAvatar>{getInitials(userName)}</ProfileAvatar>}
                name={userName}
                email={userEmail}
                menuItems={[
                  ...(isAdmin
                    ? [
                        {
                          name: formatMessage('options.profile_menu.manage_account'),
                          onClick: (): void => {
                            window.open(`${config.bccAccountUserUrl}/#user=${userBccId}`, '_blank')
                          },
                          icon: <SupervisorAccount />,
                          endIcon: <OpenInNew />
                        }
                      ]
                    : []),
                  ...(isLicenseVisible
                    ? [
                        {
                          name: formatMessage('options.profile_menu.review_licenses'),
                          onClick: onGoToLicense,
                          icon: <RecentActors />
                        }
                      ]
                    : []),

                  {
                    name: formatMessage('options.profile_menu.log_out'),
                    onClick: onLogout,
                    icon: <ExitToApp />
                  }
                ]}
                footer={
                  serialNumber || expiration ? (
                    <>
                      {serialNumber && (
                        <Typography variant="body1" color="secondary">
                          {formatMessage('serial_number', {
                            serialNumber
                          })}
                        </Typography>
                      )}
                      {expiration && (
                        <Typography variant="body2" color="secondary">
                          {formatMessage('expiration', {
                            date: datetime.formatDate(expiration, config.DATETIME.DEFAULT_DATE_FORMAT)
                          })}
                        </Typography>
                      )}
                    </>
                  ) : null
                }
              />
            )
          }
        ],
        routes,
        logo: <FaviconEmail viewBox="0 0 56 56" />
      }
    ],
    [
      activePath,
      ets,
      etsTokens.length,
      expiration,
      formatMessage,
      getInitials,
      incidentResponse,
      isAdmin,
      isLicenseVisible,
      onGoToLicense,
      onLogout,
      onNavigate,
      routes,
      serialNumber,
      userBccId,
      userEmail,
      userName
    ]
  )
}
