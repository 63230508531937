import React from 'react'
import { Routes, Route } from 'react-router-dom'

import RedirectRoute from 'global/components/lib/routes/RedirectRoute'

import routesConfig, { UiRoute } from 'sen/lib/routes/routesConfig'

import PublicRoute from 'sen/components/lib/routes/PublicRoute'
import PrivateRoute from 'sen/components/lib/routes/PrivateRoute'

import SignupPage from 'sen/components/pages/signup/Signup'
import SignupProfile from 'sen/components/pages/signup/domainFraudSignupProfile/DomainFraudSignupProfile'
import EmptyReport from 'sen/components/pages/emptyReport/EmptyReport'
import SignupComplete from 'sen/components/pages/signupComplete/SignupComplete'
import SignupPolicy from 'sen/components/pages/signupPolicy/SignupPolicy'
import SignupRemediates from 'sen/components/pages/signupRemediates/SignupRemediates'
import Settings from 'sen/components/pages/settings/Settings'

import SigninConnect from 'sen/components/pages/signinConnect/SigninConnect'

import StartTrial from 'sen/components/pages/startTrial/StartTrial'

import Dashboard from 'sen/components/pages/dashboard/Dashboard'
import AccountTakeover from 'sen/components/pages/ato/AccountTakeover'
import DomainFraud from 'sen/components/pages/domainFraud/DomainFraud'
import DomainFraudDmarcReviewWithUnknownTab from 'sen/components/pages/domainFraudDmarcReview/DomainFraudDmarcReviewWithUnknownTab'
import DomainFraudDmarcReviewWithApprovedTab from 'sen/components/pages/domainFraudDmarcReview/DomainFraudDmarcReviewWithApprovedTab'
import DomainFraudDmarcReviewWithRejectedTab from 'sen/components/pages/domainFraudDmarcReview/DomainFraudDmarcReviewWithRejectedTab'
import LicenseContent from 'sen/components/pages/license/LicenseContent'
import UnifiedReporting from 'sen/components/pages/unifiedReporting/reports/UnifiedReporting'
import ScheduledReports from 'sen/components/pages/unifiedReporting/schedules/ScheduledReports'

import Feedback from 'sen/components/pages/feedback/Feedback'
import Cleanup from 'sen/components/pages/cleanup/Cleanup'

import UnregisteredPage from 'sen/components/pages/unregisteredPage/UnregisteredPage'

import { REDIRECT_UI_ROUTES } from 'sen/configs/ui_routes'

type DisableUserCheck = boolean
export type RouteParams = [UiRoute, React.FC<any>, DisableUserCheck?]

const publicRoutes: RouteParams[] = [
  // Signup routes
  [routesConfig.SIGNIN_SIGNUP, SignupPage],
  [routesConfig.SIGNUP_PROFILE, SignupProfile, true],
  [routesConfig.SIGNUP_POLICY, SignupPolicy, true],
  [routesConfig.SIGNUP_COMPLETE, SignupComplete, true],
  [routesConfig.SIGNUP_COMPLETE_POLICY, SignupPolicy, true],
  [routesConfig.SIGNUP_REMEDIATES, SignupRemediates, true],

  // Signin routes
  [routesConfig.SIGNIN_CONNECT, SigninConnect, true],
  [routesConfig.SIGNIN_REMEDIATES, SignupRemediates, true],
  [routesConfig.FEEDBACK, Feedback, true]
]

const privateRoutes: RouteParams[] = [
  // Dashboard routes
  [routesConfig.DASHBOARD_REPORT, Dashboard],
  [routesConfig.DASHBOARD, Dashboard],
  [routesConfig.EMPTY_REPORT, EmptyReport],

  // Account takeover routes
  [routesConfig.ACCOUNT_TAKEOVER, AccountTakeover],
  [routesConfig.ACCOUNT_TAKEOVER_INCIDENTS, AccountTakeover],
  [routesConfig.ACCOUNT_TAKEOVER_INCIDENT, AccountTakeover],
  [routesConfig.ACCOUNT_TAKEOVER_INCIDENT_EXTERNAL_RECIPIENTS, AccountTakeover],
  [routesConfig.ACCOUNT_TAKEOVER_INBOX_RULES, AccountTakeover],
  [routesConfig.ACCOUNT_TAKEOVER_SIGN_INS, AccountTakeover],

  // Domain Fraud routes
  [routesConfig.DOMAIN_FRAUD, DomainFraud],

  [routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_UNKNOWN_TAB, DomainFraudDmarcReviewWithUnknownTab],

  [routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_APPROVED_TAB, DomainFraudDmarcReviewWithApprovedTab],

  [routesConfig.DOMAIN_FRAUD_DMARC_REVIEW_WITH_REJECTED_TAB, DomainFraudDmarcReviewWithRejectedTab],

  // License compliance route
  [routesConfig.LICENSE, LicenseContent],

  // Unified reporting routes
  [routesConfig.REPORT_LIST, UnifiedReporting],
  [routesConfig.REPORT_LIST_REPORT, UnifiedReporting],
  [routesConfig.SCHEDULED_REPORTS, ScheduledReports],

  // Other routes
  [routesConfig.START_TRIAL, StartTrial],
  [routesConfig.CLEANUP, Cleanup],
  [routesConfig.CLEANUP_TABLE, Cleanup],
  [routesConfig.SETTINGS, Settings]
]

const AppRoutes = (
  <Routes>
    {/* Redirects routes */}
    <Route path={REDIRECT_UI_ROUTES.DASHBOARD_ROOT.path} element={<RedirectRoute to={path => `${path}/dashboard`} />} />
    <Route
      path={REDIRECT_UI_ROUTES.ACCOUNT_TAKEOVER_CASB_INCIDENT_EXTERNAL_RECIPIENTS.path}
      element={<RedirectRoute to={path => path.replace('/incident/', '/')} />}
    />
    <Route
      path={REDIRECT_UI_ROUTES.ACCOUNT_TAKEOVER_CASB_INCIDENT.path}
      element={<RedirectRoute to={path => path.replace('/incident/', '/')} />}
    />
    <Route
      path={REDIRECT_UI_ROUTES.ACCOUNT_TAKEOVER_CASB_INCIDENT_ROOT.path}
      element={<RedirectRoute to={path => path.replace(/\/incident$/, '')} />}
    />
    <Route
      path={REDIRECT_UI_ROUTES.DOMAIN_FRAUD_DMARC_REVIEW_WITHOUT_TAB.path}
      element={<RedirectRoute to={path => `${path}/unknown`} />}
    />
    <Route
      path={REDIRECT_UI_ROUTES.DOMAIN_FRAUD_DMARC_REVIEW_ROOT.path}
      element={<RedirectRoute to={path => path.replace('/dmarc-review', '')} />}
    />

    {/* Public routes */}
    {publicRoutes
      .filter(routeparams => routeparams[0])
      .map(routeParams => {
        const [route, Component] = routeParams

        return <Route key={route.id} path={route.path} element={<PublicRoute route={route} Component={Component} />} />
      })}

    {/* Private routes */}
    {privateRoutes
      .filter(routeparams => routeparams[0])
      .map(routeParams => {
        const [route, Component] = routeParams

        return <Route key={route.id} path={route.path} element={<PrivateRoute route={route} Component={Component} />} />
      })}

    {/* If route is not registered */}
    <Route path="*" element={<PublicRoute route={routesConfig.SIGNIN_SIGNUP} Component={UnregisteredPage} />} />
  </Routes>
)

export default AppRoutes
