import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable, FilterConfig } from 'global/types/dataTables/dataTables'
import { ATTACK_TYPES, ATTACK_TYPES_BACKEND_KEYWORDS } from 'global/lib/attackTypeValidator/attackTypeValidator'

import { DataTableState, ColumnsConfig, SortFields, GridColumns } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  DATE: 'date',
  EMPLOYEE: 'employee',
  EMAIL: 'email',
  ATTACK_TYPE: 'attack_type',
  CONFIDENCE: 'confidence',
  STATUS: 'status',
  DETAILS: 'details',
  REPORT: 'report'
}

export const SORT_FIELDS: SortFields = {
  [GRID_COLUMNS.CONFIDENCE]: 'confidenceLevel',
  [GRID_COLUMNS.DATE]: 'date',
  [GRID_COLUMNS.EMPLOYEE]: 'displayName',
  [GRID_COLUMNS.EMAIL]: 'subject'
}

export const COLUMNS_CONFIG: ColumnsConfig = {
  [GRID_COLUMNS.DATE]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.EMPLOYEE]: {
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.EMAIL]: {
    sortable: true,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.ATTACK_TYPE]: {
    sortable: false,
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.CONFIDENCE]: {
    sortable: true,
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.STATUS]: {
    sortable: false,
    orderIndex: 5,
    show: true
  },
  [GRID_COLUMNS.DETAILS]: {
    sortable: false,
    orderIndex: 6,
    show: true
  },
  [GRID_COLUMNS.REPORT]: {
    sortable: false,
    orderIndex: 7,
    show: true
  }
}

const ALL_ATTACK_TYPE = 'all_attack_types'
const PREDEF_FILTER = ['isBootstrap:false']

export const FILTER_CONFIG: FilterConfig = {
  [ALL_ATTACK_TYPE]: {
    filterQuery: PREDEF_FILTER
  },
  ...Object.values(ATTACK_TYPES).reduce(
    (all: FilterConfig, attackType: string) => ({
      ...all,
      [attackType]: {
        // we want to wrap the taxonomy terms in "" so that the backend knows to do a equals query instead of a like
        filterQuery: [...PREDEF_FILTER, `taxonomy:"${ATTACK_TYPES_BACKEND_KEYWORDS[attackType]}"`]
      }
    }),
    {}
  )
}

const ITEMS_PER_PAGE = 10

// Store
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ALL_ATTACK_TYPE,
  FILTER_CONFIG,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: FILTER_CONFIG[ALL_ATTACK_TYPE].filterQuery,
  search: '',
  sort: [{ field: GRID_COLUMNS.DATE, dir: 'desc' }]
}

export const spAttacksSlice = createSlice({
  name: 'DATA_TABLE/SP_ATTACKS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = spAttacksSlice.actions

export default spAttacksSlice.reducer
